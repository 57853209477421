import React from 'react'

//Assets
import ProductImage from '../../../assets/img/monstore/TELKOMSELASSET-10.png'
import FunnyImage from '../../../assets/img/monstore/TELKOMSELASSET-02.png'
import { Button } from '../../anti/buttons/buttons'

import { navigate } from "gatsby"

const SuccessPage = ({ products }) => {

   const handleNavigate = () => navigate("/")

   return (
      <>
         <section className="success-page">
            <div className="container mw-sm">
               <div className="img-wrapper pt-5 px-5">
                  <img src={FunnyImage} className="img-fluid" alt="funny png" />
               </div>
               <div className="content py-5">
                  <div className="row">
                     <div className="col-12 text-center">
                        <h2 className="title">
                           PENUKARAN TELKOMSEL POIN <br /> BERHASIL!
                        </h2>
                        <p className="description mt-3 px-2">
                           Silahkan tunggu selagi kami memproses penukaran Telkomsel POIN kamu dengan merchandise Telkomsel POIN x Monstore.
                           Apabila kamu membutuhkan bantuan, silahkan hubungi kami di <span className="text-primary"><a target="_blank" href="mailto:info@heymonstore.com">info@heymonstore.com</a></span>
                        </p>
                     </div>
                  </div>
               </div>
               <div className="products py-1">
                  <div className="row mt-5">
                     {products?.map((item, index) => (
                        <div className="col-6 p-3">
                           <div className="product-wrapper">
                              <img src={item.categoryInfo?.image?.mediaItemUrl} className="img-fluid" alt="product" />
                           </div>
                           <div className="product-content mt-3">
                              <h6 className="p-0 m-0">{item.name}</h6>
                              <p className="p-0 mt-2">Tukar {item.categoryInfo?.point ? item.categoryInfo?.point : 0} poin</p>
                           </div>
                        </div>
                     ))}
                  </div>

               </div>
            </div>
         </section>

         <div className="container mw-sm home-navigate p-4">
            <Button variant="secondary" link={"https://my.telkomsel.com/app/flexible-time-promo/monstore"}>
               Kembali ke Web Telkomsel
            </Button>
         </div>

      </>
   )
}

export default SuccessPage