import React from "react"

//SEO
import SEO from '../components/seo'
import SuccessComp from '../components/pages/success'

import { useQuery, gql } from '@apollo/client'


const SuccessPage = ({ pageContext }) => {

  const { data, loading } = useQuery(Q_PRODUCTS)

  return (
    <>
      <SEO
        title="Success"
      />
      <SuccessComp products={data?.redeemCategories?.nodes} />
    </>
  )
}

export default SuccessPage

const Q_PRODUCTS = gql`
  query PRODUCT_REDEEM {
    redeemCategories {
      nodes {
        name
        categoryInfo {
          point
          image {mediaItemUrl}
        }
      }
    }
}
`


const Q_VOUCHERS = gql`
  query getRedeemCode($redeemId: ID!) {
    redeemCode(id: $redeemId, idType: SLUG) {
      title
      redeemAt {
        redeemDate
        size
      }
      redeemCategories {
        nodes {
          slug
          categoryInfo {
            image {
              mediaItemUrl
            }
            size {
              xl {
                stock
                outStock
              }
              small {
                stock
                outStock
              }
              medium {
                stock
                outStock
              }
              large {
                stock
                outStock
              }
            }
            oneSize
          }
          name
        }
      }
    }
  }
`


// mutation MyMutation {
//    __typename
//    submit2Redeem(input: { clientMutationId: "1", address: "adadsd", city: "jakarta", code: "MASVKA3V6RIA", zipCode: "999999", province: "jakarta", fullName: "john doe", phoneNumber: "086273846232", email: "jidan@antikode.com" }) {
//       status
//       message
//       clientMutationId
//    }
// }


// import React from "react"
// import SEO from "../components/seo"
// import { Container, Section } from "../components/anti/grid/grid"
// import { Link } from "../components/anti/utils/utils"

// // Images
// import imgSuccess from "../assets/img/status/img_success.png"

// const SuccessPage = () => {
//   return (
//     <>
//       <SEO title="Result Page" />

//       <Section className="section-status-redeem">
//         <Container className="mw-sm">
//           <div>
//             <img
//               src={imgSuccess}
//               className="img-fluid"
//               alt="Logo"
//               width="auto"
//             />
//             <div className="text-center">
//               <h1 className="h1">Penukaran Telkomsel POIN Berhasil!</h1>
//               <p>
//                 Silahkan tunggu selagi kami memproses penukaran Telkomsel POIN
//                 kamu dengan merchandise Telkomsel POIN x Monstore. Apabila kamu
//                 membutuhkan bantuan, silahkan hubungi kami di{" "}
//                 <Link to="mailto:info@heymonstore.com">
//                   info@heymonstore.com
//                 </Link>{" "}
//               </p>
//             </div>
//           </div>
//         </Container>
//       </Section>
//     </>
//   )
// }

// export default SuccessPage
