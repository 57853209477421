import React, { useEffect, useLayoutEffect } from "react"
import { navigate } from "gatsby"

// import { fadeInUp } from "./utils/animations"

import Navbar from "./common/navbar/navbar"
import Footer from "./common/footer/footer"

const Layout = ({ children }) => {
  // Mobile viewport height workaround
  // prevent quirky behaviour on mobile when cover is set to 100vh
  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  // Transition link
  // for smooth transition effect
  useLayoutEffect(() => {
    const wrapper = document.querySelector("main")
    wrapper.classList.add("animated", "fadeIn")
  }, [])
  useEffect(() => {
    const wrapper = document.querySelector("main")
    const anchor = document.querySelectorAll("a")
    anchor.forEach(item => {
      const navigation = e => {
        const redirect = item.getAttribute("href")
        e.preventDefault()
        wrapper.classList.remove("fadeIn")
        wrapper.classList.add("fadeOut")
        setTimeout(() => {
          navigate(redirect)
        }, 250)
      }
      if (item.getAttribute("href") && !item.getAttribute("target")) {
        item.addEventListener("click", navigation)
      }
    })
    return () => {
      anchor.forEach(item => {
        const navigation = e => {
          const redirect = item.getAttribute("href")
          e.preventDefault()
          wrapper.classList.remove("fadeIn")
          wrapper.classList.add("fadeOut")
          setTimeout(() => {
            navigate(redirect)
          }, 250)
        }
        if (item.getAttribute("href") && !item.getAttribute("target")) {
          item.removeEventListener("click", navigation)
        }
      })
    }
  }, [])

  // Scroll trigger animation
  // useEffect(() => {
  //   fadeInUp()
  // }, [])

  return (
    <>
      {/* <Navbar /> */}
      <main>
        {children}
        {/* <Footer /> */}
      </main>
    </>
  )
}

export default Layout
