import React from 'react'

//Components
import { Button } from '../../anti/buttons/buttons'

//Assets
import FunnyImage from '../../../assets/img/monstore/TELKOMSELASSET-03.png'

import { navigate } from "gatsby"

export default function NoValidComponents() {

   const handleNavigate = () => navigate("/")

   return (
      <>
         <section className="no-valid py-main">
            <div className="container mw-sm">
               <div className="img-wrapper pt-5 px-5">
                  <img src={FunnyImage} className="img-fluid" alt="funny png" />
               </div>
               <div className="content py-5">
                  <div className="row">
                     <div className="col-12 text-center">
                        <h1 className="title">
                           Maaf kode <br /> voucher tidak valid
                        </h1>
                        <p className="description text-justify mt-4 px-2">
                           Ini mungkin bisa terjadi apabila link voucher yang kamu masukkan sudah di klaim sebelumnya. Hubungi <span className="text-primary"><a target="_blank" href="mailto:info@heymonstore.com">info@heymonstore.com</a></span> apabila kamu merasa ini adalah sebuah kesalahan.
                        </p>
                        {/* <p className="description mt-3 px-5">
                           Hubungi <span className="text-primary">
                              <a target="_blank" href="mailto:info@heymonstore.com">info@heymonstore.com</a>
                           </span> apabila kamu merasa ini adalah sebuah kesalahan.
                        </p> */}
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <div className="container mw-sm home-navigate p-4">
            <Button variant="secondary" link={"https://my.telkomsel.com/app/flexible-time-promo/monstore"}>
               Kembali ke Web Telkomsel
            </Button>
         </div>
      </>
   )
}