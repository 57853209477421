import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import GatsbyLink from "gatsby-link"
import GatsbyBackgroundImage from "gatsby-background-image"

import ratio1by1 from "./img/r1-1.png"
import ratio2by1 from "./img/r2-1.png"
import ratio3by2 from "./img/r3-2.png"
import ratio2by3 from "./img/r2-3.png"
import ratio5by4 from "./img/r5-4.png"
import ratio4by5 from "./img/r4-5.png"
import ratio16by9 from "./img/r16-9.png"

//
// Image Ratio
//
export const ImgRatio = () => {
  return (
    // prettier-ignore
    <>
      <img className="img-ratio img-ratio-1-1" src={ratio1by1} alt="1by1" width="100%" />
      <img className="img-ratio img-ratio-2-1" src={ratio2by1} alt="2by1" width="100%" />
      <img className="img-ratio img-ratio-3-2" src={ratio3by2} alt="3by2" width="100%" />
      <img className="img-ratio img-ratio-2-3" src={ratio2by3} alt="2by3" width="100%" />
      <img className="img-ratio img-ratio-5-4" src={ratio5by4} alt="5by4" width="100%" />
      <img className="img-ratio img-ratio-4-5" src={ratio4by5} alt="4by5" width="100%" />
      <img className="img-ratio img-ratio-16-9" src={ratio16by9} alt="16by9" width="100%" />
    </>
  )
}

//
// Link
//
export const Link = props => {
  // If <Link> target is "_blank", show normal <a> element
  if (props.target === "_blank") {
    return (
      // prettier-ignore
      <a
        className={typeof props.children === "string" ? "btn btn-link" : props.className ? props.className : ""}
        href={props.to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    )

    // If <Link> target is "", show normal <Link> element
  } else if (props.target === "") {
    const config = process.env.ROOT_URL
    const rawUrl = props.to && props.to
    const url = rawUrl.split(config)
    return (
      <GatsbyLink {...props} to={url.length > 1 ? url[1] : url[0]}>
        {props.children}
      </GatsbyLink>
    )
  } else {
    // If <Link> starts with "/" which indicates internal Gatsby Link
    if (props.to && props.to.startsWith("/")) {
      return <GatsbyLink {...props}>{props.children}</GatsbyLink>

      // If props.to is blank
    } else if (props.to === "") {
      return <div {...props}>{props.children}</div>

      // Default
    } else {
      return (
        <a {...props} href={props.to} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      )
    }
  }
}

//
// Background Image
//
export const BackgroundImage = props => {
  if (typeof props.fluid === "object") {
    return (
      <GatsbyBackgroundImage {...props}>{props.children}</GatsbyBackgroundImage>
    )
  } else {
    return (
      <div
        className={props.className}
        style={{
          background: `url(${props.fluid}) no-repeat center`,
          backgroundSize: "cover",
        }}
      >
        {props.children}
      </div>
    )
  }
}

BackgroundImage.propTypes = {
  fluid: PropTypes.any,
  Tag: PropTypes.string,
  className: PropTypes.string,
}

BackgroundImage.defaultProps = {
  Tag: "div",
  className: "",
}
