import React, { useState } from "react"
import { withFormik, Form } from "formik"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import { gql, useMutation, useQuery } from "@apollo/client"
import * as Yup from "yup"
// import { gql, useMutation } from "@apollo/client"

import Layout from "../components/layout"

import { Container, Section } from "../components/anti/grid/grid"
import { Button } from "../components/anti/buttons/buttons"
import imgLoading from "../assets/img/brand/logo-monstore.png"
import { InputFormik, Select } from "../components/anti/form/form"

import logoTselMonstore from "../assets/img/brand/logo_tsel-monstore.png"

const SUBMIT = gql`
  mutation redeem(
    $code: String
    $fullName: String
    $phoneNumber: String
    $city: String
    $address: String
    $email: String
    $province: String
    $zipCode: String
    $size: SizeRedeemEnum
  ) {
    submit2Redeem(
      input: {
        clientMutationId: ""
        code: $code
        fullName: $fullName
        phoneNumber: $phoneNumber
        city: $city
        address: $address
        email: $email
        province: $province
        zipCode: $zipCode
        size: $size
      }
    ) {
      status
      message
    }
  }
`

const SUBMIT_V2 = gql`
  mutation redeem2(
    $code: String
    $fullName: String
    $phoneNumber: String
    $city: String
    $address: String
    $email: String
    $province: String
    $zipCode: String
  ) {
    submit2Redeem(
      input: {
        clientMutationId: ""
        code: $code
        fullName: $fullName
        phoneNumber: $phoneNumber
        city: $city
        address: $address
        email: $email
        province: $province
        zipCode: $zipCode
      }
    ) {
      status
      message
    }
  }
`

const getVocher = gql`
  query getRedeemCode($redeemId: ID!) {
    redeemCode(id: $redeemId, idType: SLUG) {
      title
      redeemAt {
        redeemDate
        size
      }
      redeemCategories {
        nodes {
          slug
          categoryInfo {
            image {
              mediaItemUrl
            }
            size {
              xl {
                stock
                outStock
              }
              small {
                stock
                outStock
              }
              medium {
                stock
                outStock
              }
              large {
                stock
                outStock
              }
            }
            oneSize
          }
          name
        }
      }
    }
  }
`

const SubmitForm = ({
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  redeemId,
  isLoading,
  onSizeLoading,
}) => {
  const { loading, data } = useQuery(getVocher, {
    variables: { redeemId },
  })

  const checkID = data && data.redeemCode
  const checkAvailable =
    checkID && checkID.redeemCategories.nodes[0].categoryInfo
  const obj = checkAvailable && checkAvailable.size

  const provinsi = {
    provinsi: [
      { label: "aceh", value: "Aceh" },
      { label: "sumatera utara", value: "Sumatera Utara" },
      { label: 13, value: "Sumatera Barat" },
      { label: 14, value: "Riau" },
      { label: 15, value: "Jambi" },
      { label: 16, value: "Sumatera Selatan" },
      { label: 17, value: "Bengkulu" },
      { label: 18, value: "Lampung" },
      { label: 19, value: "Kepulauan Bangka Belitung" },
      { label: 21, value: "Kepulauan Riau" },
      { label: 31, value: "Dki Jakarta" },
      { label: 32, value: "Jawa Barat" },
      { label: 33, value: "Jawa Tengah" },
      { label: 34, value: "Di Yogyakarta" },
      { label: 35, value: "Jawa Timur" },
      { label: 36, value: "Banten" },
      { label: 51, value: "Bali" },
      { label: 52, value: "Nusa Tenggara Barat" },
      { label: 53, value: "Nusa Tenggara Timur" },
      { label: 61, value: "Kalimantan Barat" },
      { label: 62, value: "Kalimantan Tengah" },
      { label: 63, value: "Kalimantan Selatan" },
      { label: 64, value: "Kalimantan Timur" },
      { label: 65, value: "Kalimantan Utara" },
      { label: 71, value: "Sulawesi Utara" },
      { label: 72, value: "Sulawesi Tengah" },
      { label: 73, value: "Sulawesi Selatan" },
      { label: 74, value: "Sulawesi Tenggara" },
      { label: 75, value: "Gorontalo" },
      { label: 76, value: "Sulawesi Barat" },
      { label: 81, value: "Maluku" },
      { label: 82, value: "Maluku Utara" },
      { label: 91, value: "Papua Barat" },
      { label: 94, value: "Papua" },
    ],
  }

  let provinsiArr = []

  provinsi.provinsi.map(item =>
    provinsiArr.push({
      label: item.value,
      value: item.value.toLowerCase(),
    })
  )

  let availableSizes = []

  let giftRedeem
  if (checkID && checkID != null) {
    giftRedeem = `Kamu akan me-redeem <strong>${checkID &&
      checkID.redeemCategories.nodes[0].name}</strong>`
    if (checkID && checkID.redeemAt.redeemDate === null) {
      if (checkAvailable && checkAvailable.oneSize != null) {
        availableSizes.push({
          label: "One Size",
          value: "one size",
        })
      } else if (checkAvailable && checkAvailable.oneSize === null) {
        const sizeArr = obj && Object.keys(obj).map(key => [key, obj[key]])
        sizeArr &&
          sizeArr.map(item => {
            if (item[1].stock && item[1].stock > 0 && !item[1].outStock) {
              availableSizes.push({
                label: item[0] === "xl" ? "EXTRA LARGE" : item[0].toUpperCase(),
                value: item[0],
              })
            }
          })
      }
    } else if (checkID && checkID.redeemAt.redeemDate != null) {
      giftRedeem = `Kode redeem sudah terpakai`
      navigate(`/tsel/failed/${redeemId}`)
    }
  } else if (checkID === null) {
    giftRedeem = `Kode redeem tidak valid`
    availableSizes.push({
      label: "Tidak Tersedia",
      value: "tidak tersedia",
    })
    navigate(`/tsel/failed/${redeemId}`)
  }

  return loading ? (
    <Section className="section-redeem">
      <Container className="mw-sm">
        <div className="text-center">
          <img
            src={imgLoading}
            className="img-fluid mb-3"
            alt="Logo"
            width="auto"
          />
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      </Container>
    </Section>
  ) : (
    <>
      {/* <Section className="header">
        <Container className="mw-sm">
          <h1 className="h1 sr-only">Redeem Telkomsel POIN</h1>
          <img
            src={logoTselMonstore}
            className="img-fluid"
            alt="Telkomsel POIN x Monstore"
          />
        </Container>

      </Section> */}

      <div className="sc-home">
        <div className="home-header container mw-sm py-4 bg-primary">
          <h1 className="title-header text-center m-0">claim poin telkomsel</h1>
        </div>
      </div>
      <div className="sc-home">
        <Section className="bg-black">

          <Container className="mw-sm">
            <Form>
              <div className="row">
                {/* <div className="col-12"></div> */}
                <div className="col-md-12 mb-3">
                  <InputFormik
                    name="path"
                    placeholder="Link"
                    className=""
                    errors={errors}
                    disabled={true}
                  />
                  <p
                    className="help-block"
                    dangerouslySetInnerHTML={{ __html: giftRedeem }}
                  ></p>
                </div>
                <div className="col-md-12 mb-3 mt-5">
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={
                        checkID &&
                        checkID.redeemCategories.nodes[0].categoryInfo.image &&
                        checkID.redeemCategories.nodes[0].categoryInfo.image
                          .mediaItemUrl
                      }
                      className="img-fluid dark"
                      alt="Logo"
                      width="auto"
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 mt-3">
                  <Select
                    placeholder="Pilih size"
                    // label="Pilih size"
                    name="size"
                    id="size"
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={availableSizes}
                    // defaultValue={availableSizes.length > 0 && availableSizes[0]}
                    isSearchable={true}
                  />
                  {touched && errors && touched.size && errors.size && (
                    <div className="validation-error">{errors.size}</div>
                  )}
                </div>
                <div className="col-md-12 mb-1 mt-3">
                  <h3 className="h3 text-gray">Data Pribadi</h3>
                </div>
                <div className="col-md-12 mb-3">
                  <InputFormik
                    className="form-data-input"
                    name="fullName"
                    placeholder="Nama lengkap"
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <InputFormik
                    className="form-data-input"
                    name="email"
                    placeholder="Email"
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <InputFormik
                    className="form-data-input"
                    name="phoneNumber"
                    placeholder="Nomor handphone"
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="col-md-12 mb-1 mt-3">
                  <h3 className="h3 text-gray">Alamat Pengiriman</h3>
                </div>
                <div className="col-12 mb-3">
                  <InputFormik
                    className="form-data-input"
                    name="address"
                    placeholder="Alamat lengkap"
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="col-12 mb-3 mt-3">
                  <Select
                    placeholder="Pilih provinsi"
                    // classNamePrefix="select form-data-input"
                    name="province"
                    id="province"
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched}
                    errors={errors}
                    options={provinsiArr}
                  />
                  {touched && errors && touched.province && errors.province && (
                    <div className="validation-error">{errors.province}</div>
                  )}
                </div>
                <div className="col-12 mb-3">
                  <InputFormik
                    name="city"
                    className="form-data-input"
                    placeholder="Kota"
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="col-12 mb-3">
                  <InputFormik
                    name="zipCode"
                    className="form-data-input"

                    placeholder="Kode Pos"
                    touched={touched}
                    errors={errors}
                  />
                </div>
              </div>
              <Button type="submit" variant="btn btn-primary w-100 mt-4">
                {isLoading || onSizeLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  "Redeem"
                )}
              </Button>
            </Form>

          </Container>

        </Section>
      </div>
    </>
  )
}

const RedeemFormPage = ({ code }) => {


  const [formValues, setFormValues] = useState({
    fullName: "",
    phoneNumber: "",
    city: "",
    size: "",
    address: "",
    email: "",
    province: "",
    zipCode: "",
  })
  //mutation
  const [submitVoucher, { loading }] = useMutation(SUBMIT)
  const [submitVoucher2, { loading: onSizeLoading }] = useMutation(SUBMIT_V2)

  const FormValidation = withFormik({
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required("Harap Isi Nama Anda"),
      email: Yup.string()
        .email()
        .required("Harap Isi Email Anda"),
      phoneNumber: Yup.string().required("Harap Isi Nomor Telepon Anda"),
      address: Yup.string().required("Harap Isi Alamat Anda"),
      city: Yup.string().required("Harap Isi Kota Anda"),
      size: Yup.object().required("Harap Pilih Ukuran Ukuran"),
      province: Yup.object().required("Harap Pilih Provinsi"),
      zipCode: Yup.string().required("Harap Isi Kode Post Anda"),
    }),
    mapPropsToValues({
      fullName,
      email,
      phoneNumber,
      address,
      province,
      size,
      city,
      zipCode,
      path,
    }) {
      return {
        path: code,
        fullName: fullName || formValues.fullName,
        email: email || formValues.email,
        phoneNumber: phoneNumber || formValues.phoneNumber,
        address: address || formValues.address,
        province: province || formValues.province,
        size: size || formValues.size,
        city: city || formValues.city,
        zipCode: zipCode || formValues.zipCode,
      }
    },
    handleSubmit(values) {
      setFormValues(values)
      // onClick
      if (values.size.value === "one size") {
        submitVoucher2({
          variables: {
            code: values.path,
            fullName: values.fullName,
            phoneNumber: values.phoneNumber,
            city: values.city,
            size: values.size.label.toUpperCase() === "EXTRA LARGE" ? "XL" : values.size.label.toUpperCase(),
            address: values.address,
            email: values.email,
            province: values.province.label,
            zipCode: values.zipCode,
          },
        })
          .then(result => {
            const res = result.data.submit2Redeem
            if (res.status === "success") {
              navigate(`/tsel/success/${code}`)
            } else if (res.status === "error") {
              navigate(`/tsel/failed/${code}`)
            }
          })
          .catch(err => console.log(err, "ERRRR"))
      } else {
        submitVoucher({
          variables: {
            code: values.path,
            fullName: values.fullName,
            phoneNumber: values.phoneNumber,
            city: values.city,
            size: values.size.label.toUpperCase() === "EXTRA LARGE" ? "XL" : values.size.label.toUpperCase(),
            address: values.address,
            email: values.email,
            province: values.province.label,
            zipCode: values.zipCode,
          },
        })
          .then(result => {
            const res = result.data.submit2Redeem
            if (res.status === "success") {
              navigate(`/tsel/success/${code}`)
            } else if (res.status === "error") {
              navigate(`/tsel/failed/${code}`)
            }
          })
          .catch(err => console.log(err, "ERRRRRR"))
      }
    },
  })(SubmitForm)

  return (
    <Layout>
      <FormValidation
        redeemId={code}
        isLoading={loading}
        onSizeLoading={onSizeLoading}
      />
    </Layout>
  )
}

export default RedeemFormPage
