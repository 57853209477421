import React, { useState, useEffect } from "react"
import { Field } from "formik"
import ReactSelect from "react-select"
import ReactDatePicker from "react-datepicker"
// import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography"
import Slider from "@material-ui/core/Slider"
import { Button } from "../../anti/buttons/buttons"

import PropTypes from "prop-types"

//Select
export const Select = ({
  variant,
  className,
  classNamePrefix,
  isDisabled,
  isLoading,
  isClearable,
  isRtl,
  isSearchable,
  defaultValue,
  id,
  onChange,
  onBlur,
  label,
  options,
  value,
  error,
  touched,
  placeholder,
}) => {
  const handleChange = arg => {
    onChange(id, arg)
  }
  const handleBlur = () => {
    onBlur(id, true)
  }
  return (
    <>
      {label && <label>{label}</label>}
      <ReactSelect
        id={id}
        className={`form-select ${variant} ${className}`}
        classNamePrefix={classNamePrefix}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        options={options}
        onChange={handleChange}
        // onBlur={handleBlur}
        value={value}
        placeholder={placeholder}
      />
      {error && touched && (
        <div style={{ color: "red", marginTop: ".5rem" }}>{error}</div>
      )}
    </>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  options: PropTypes.any,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  defaultValue: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRtl: PropTypes.bool,
  isSearchable: PropTypes.bool,
}

Select.defaultProps = {
  variant: "basic-single",
  className: "",
  classNamePrefix: "select",
  isSearchable: false,
  placeholder: "Choose...",
}

// Input Text
export const InputText = ({
  id,
  label,
  placeholder,
  inputType,
  value,
  onChange,
  onBlur,
  error,
  touched,
  className,
  inputClassName,
  disabled
}) => {
  const [setState] = useState({
    value: "",
  })
  const handleChange = e => {
    const re = /^[0-9\b]+$/
    if (e.target.value === "" || re.test(e.target.value)) {
      setState((value = e.target.value))
    }
  }
  return (
    <>
      {label && <label for={id}>{label}</label>}
      <div className={`form-control-wrapper ${className}`}>
        {/* prettier-ignore */}
        <input
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          className={`form-control ${inputClassName}`}
          type={inputType === "email" ? "email" : "text"}
          value={value}
          // onChange={onChange}
          onBlur={onBlur}
          onChange={inputType === "phonenumber" ? handleChange : inputType === "telephonenumber" ? handleChange : inputType === "ktpnumber" ? handleChange : onChange}
          minLength={inputType === "phonenumber" ? "10" : inputType === "telephonenumber" ? "7" : inputType === "ktpnumber" ? "16" : ""}
          maxLength={inputType === "phonenumber" ? "12" : inputType === "telephonenumber" ? "10" : inputType === "ktpnumber" ? "16" : ""}
        />
        {error && touched && (
          <div style={{ color: "red", marginTop: ".5rem" }}>{error}</div>
        )}
      </div>
    </>
  )
}

InputText.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
}

InputText.defaultProps = {
  className: "",
  inputClassName: "",
  placeholder: "Enter text",
  inputType: "",
}

export const InputFormik = ({
  name,
  placeholder,
  touched,
  errors,
  disabled,
  className,
}) => {
  return (
    <>
      <Field
        className={`form-control ${className}`}
        name={name}
        placeholder={placeholder}
        disabled={disabled}

      />
      {touched && errors && touched[name] && errors[name] && (
        <div className="validation-error">{errors[name]}</div>
      )}
    </>
  )
}

InputFormik.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
}

export const DatePicker = ({
  selected,
  onChange,
  name,
  placeholder,
  wrapperClassName,
  className,
  touched,
  errors,
  setFieldValue,
}) => {
  const onDateChange = (selected, setFieldValue) =>
    setFieldValue(name, selected)

  return (
    <>
      <ReactDatePicker
        name={name}
        className={`form-control ${className}`}
        placeholderText={placeholder}
        wrapperClassName={wrapperClassName}
        selected={selected}
        onChange={
          setFieldValue ? value => onDateChange(value, setFieldValue) : onChange
        }
      />
      {touched && errors && touched[name] && errors[name] && (
        <div className="validation-error">{errors[name]}</div>
      )}
    </>
  )
}

DatePicker.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
}

DatePicker.defaultProps = {
  name: "",
  placeholder: "",
  wrapperClassName: "",
  className: "",
}

// Check Box
export const Checkbox = props => {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={props.value}
              {...props}
              checked={field.value.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter(
                    value => value !== props.value
                  )
                  form.setFieldValue(props.name, nextValue)
                } else {
                  const nextValue = field.value.concat(props.value)
                  form.setFieldValue(props.name, nextValue)
                }
              }}
            />
            <label className="custom-control-label" for={props.value}>
              {props.label}
            </label>
          </div>
        </div>
      )}
    </Field>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string,
}

Checkbox.defaultProps = {
  name: "",
}

// Radio Button
export const Radiobutton = ({ id, name, className, value, label }) => {
  return (
    <label className="radio-wrapper">
      {label}
      <input
        className={`radio-button ${className}`}
        type="radio"
        id={id}
        defaultChecked={value}
        name={name}
        value={value}
      />
      <span className="radio-button" />
    </label>
  )
}

Radiobutton.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
}

Radiobutton.defaultProps = {
  className: "",
}

// Range Slider
export const RangeSLider = ({ className, defaultValue, label }) => {
  const [value, setValue] = React.useState(defaultValue)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div>
      {label && (
        <Typography id="range-slider" gutterBottom>
          {label}
        </Typography>
      )}
      <Slider
        className={className}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
    </div>
  )
}

Radiobutton.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.array,
}

Radiobutton.defaultProps = {
  className: "",
  defaultValue: [25, 30],
}

// Input Bootstrap
export const InputBootstrap = ({
  id,
  label,
  className,
  placeholder,
  type,
  length,
  encrypt,
  callback,
  required,
  errorMessage,
  minLength,
  maxLength,
  onFocus,
  disabled,
  value,
  onKeyDown,
  inputRef,
  promo,
  checkFunc,
}) => {
  const [inputValue, setInputValue] = useState({ encrypted: "", raw: "" })
  const [isEncrypted, setIsEncrypted] = useState(true)

  useEffect(() => {
    // const innerHeight = window.innerHeight
    // const input = document.getElementById(id) && document.getElementById(id)
    // const keyboard =
    //   document.getElementById("keyboard") && document.getElementById("keyboard")
    // const getOffset = el => {
    //   const rect = el.getBoundingClientRect()
    //   return {
    //     left: rect.left + window.scrollX,
    //     top: rect.top + window.scrollY,
    //   }
    // }
  }, [])

  const encryptInput = e => {
    const re = /^[0-9\b]+$/
    const raw = e.target.value.split("")
    let temp = inputValue.encrypted.split("")
    let encyption = temp

    if (raw.length > (length || 2) && raw.length > temp.length) {
      encyption.push("*")
    } else if (raw.length > (length || 2) && raw.length < temp.length) {
      temp.pop()
    } else {
      encyption = raw
    }
    if (type && type === "number") {
      if (e.target.value === "" || re.test(e.target.value)) {
        setInputValue({ encrypted: encyption.join(""), raw: e.target.value })
      }
    } else {
      setInputValue({ encrypted: encyption.join(""), raw: e.target.value })
    }
  }

  const getInputValue = e => {
    callback && callback(e.target.value)
  }

  // const showValue = () => {
  //   setIsEncrypted(false)
  // }

  // const hideValue = () => {
  //   setIsEncrypted(true)
  // }

  const showValue = () => {
    setIsEncrypted(!isEncrypted)
  }

  const getValue = e => {
    if (e.keyCode === 13) {
      onKeyDown(e.target.value)
    }
  }

  return (
    <>
      <div className={`form-control-wrapper ${className ? className : ""}`}>
        {label && <label>{label}</label>}
        <div className={`input-group ${className ? className : ""}`}>
          <input
            id={id}
            type={`${type !== "number" ? type : "text"}`}
            placeholder={placeholder}
            className={`form-control ${encrypt ? "form-main" : ""}`}
            onChange={
              encrypt
                ? e => {
                  encryptInput(e)
                  callback(inputValue.raw)
                }
                : getInputValue
            }
            minLength={minLength || 0}
            required={required || false}
            onFocus={onFocus}
            maxLength={maxLength}
            disabled={disabled}
            value={value || (encrypt && inputValue.raw)}
            onKeyDown={onKeyDown && getValue}
            ref={inputRef}
          />
          {encrypt && (
            <span className="unhide input-group-append" onClick={showValue}>
              {isEncrypted ? (
                <i className="fas fa-eye"></i>
              ) : (
                <i className="fas fa-eye-slash"></i>
              )}
            </span>
          )}
          {promo && (
            <span className="input-group-append cek-button">
              <Button variant="link" click={checkFunc}>
                Cek
              </Button>
            </span>
          )}
          {encrypt && (
            <div
              className={`form-control ${disabled ? "dl-disabled-input" : ""}`}
            >
              {encrypt && (isEncrypted ? inputValue.encrypted : inputValue.raw)}
            </div>
          )}
          {errorMessage && (
            <div className="invalid-feedback">{errorMessage}</div>
          )}
        </div>
      </div>
    </>
  )
}

InputBootstrap.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  encrypt: PropTypes.bool, // enable encryption
  length: PropTypes.number,
  callback: PropTypes.func, // returns input value
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
}
