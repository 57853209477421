import React, { useState } from "react"
import PropTypes from "prop-types"
import { nanoid } from "nanoid"

import { Link } from "../../anti/utils/utils"

export const Social = ({ className }) => {
  const [id] = useState(nanoid)

  const social = [
    { iconName: "fa-facebook-square", url: "#" },
    { iconName: "fa-twitter-square", url: "#" },
    { iconName: "fa-instagram", url: "#" },
  ]

  return (
    <ul className={`social ${className}`}>
      {social.map((item, i) => {
        return (
          <li key={`${id}${i}`}>
            <Link to={item.url}>
              <i className={`fab ${item.iconName}`} />
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

Social.propTypes = {
  className: PropTypes.string, // Set optional classes
}

Social.defaultProps = {
  className: "",
}
